<template>
    <div>
        <alert-component v-if="!item.id && total >= tenant[0].app_plan.customers" :alert="alerts.limit"></alert-component>
        <v-form ref="form" v-if="item.id || total < tenant[0].app_plan.customers">
            <v-row dense v-if="!item.id">
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.finalidade"
                        :items="items.finalidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.como_conheceu"
                        :items="me.branch.howtoknowitems && me.branch.howtoknowitems.length > 0 ? me.branch.howtoknowitems : items.default_howtoknowitems"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        item-text="description"
                        item-value="description"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Como conheceu?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="type_contact"
                        :items="items.contacts"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="type_contact == 'E-mail'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.email"
                        v-mask=""
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col v-if="type_contact == 'Telefone'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>  
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-btn
                        color="primary"
                        @click="validateCheckNew"
                        :class="$css_button"
                    >
                        <span>Pesquisar</span>
                    </v-btn>
                </v-col>   
            </v-row>

            <v-row v-if="!item.id && check_new.status == true" dense>
                <v-col v-if="check_new.clients.data && check_new.clients.data.length > 0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                   <alert-component :alert="alerts.check_new"></alert-component> 
                   <v-card
                        class="mx-auto"
                        outlined
                    >
                        <v-data-table
                            :headers="headers.clients"
                            :items="check_new.clients.data"
                            :items-per-page="10"
                            sort-by="id"
                            class="elevation-1 table-striped"
                            :footer-props="{
                            'items-per-page-text':'Itens por página',   
                            'items-per-page-options': [5, 10],
                            'items-per-page-all-text': '',
                            'page-text': '{0}-{1} de {2}'
                        }"
                        >
                            <template v-slot:[`item.nome`]="{ item }">
                                <span :class="$css_report_list">{{ item.nome }}</span>
                            </template>

                            <template v-slot:[`item.telefone`]="{ item }">
                                <span :class="$css_report_list" v-if="item.telefone">{{ item.telefone | VMask(maskPhone(item.telefone)) }}</span>
                                <span :class="$css_report_list" v-if="item.telefone2">{{ item.telefone2 | VMask(maskPhone(item.telefone2)) }}</span>
                            </template>

                            <template v-slot:[`item.email`]="{ item }">
                                <span :class="$css_report_list">{{ item.email }}</span>
                            </template>

                            <template v-slot:[`item.details`]="{ item }">
                                <span :class="$css_report_list">
                                    <span :class="$css_report_list">Data Cadastro: {{ item.created_at }}</span> <br />
                                    <span :class="$css_report_list">Data Atualizaçāo: {{ item.updated_at }}</span> <br />
                                    <span :class="$css_report_list">Número de FACS: {{ item.facs.length }}</span> 
                                </span>
                            </template>
                            

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-card-actions>
                                    <v-tooltip top v-if="(me.profile == 'Master' || me.profile == 'Gerente') && item.facs && item.facs.length > 0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon class="ml-8" @click="showFacs(item.facs)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-face-agent</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>FACS cadastradas - visualizar</span>
                                    </v-tooltip>

                                    <v-tooltip top v-if="item.property_owners && item.property_owners.length > 0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon class="ml-8" @click="showPropertyOwners(item.property_owners)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-home</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Proprietario de imóveis - visualizar</span>
                                    </v-tooltip>

                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="setFac(item)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-folder-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Gerar FAC </span>
                                    </v-tooltip>
                                </v-card-actions>     
                            </template>
                        </v-data-table>
                    </v-card>  

                    <!-- property_owners -->    
                    <v-card class="mt-6" v-if="show.property_owners && items.property_owners.length > 0">
                        <div class="text-center text-h6">Imóveis desse proprietário</div>
                         <v-data-table
                            :headers="headers.property_owners"
                            :items="items.property_owners"
                            :items-per-page="10"
                            class="elevation-1 table-striped"
                            :footer-props="{
                            'items-per-page-text':'Itens por página',   
                            'items-per-page-options': [5, 10],
                            'items-per-page-all-text': '',
                            'page-text': '{0}-{1} de {2}'
                        }"
                        >
                            <template v-slot:[`item.endereco`]="{ item }">
                                <span :class="$css_report_list">{{ item.endereco }}</span>
                                <span :class="$css_report_list" v-if="item.numero">, {{ item.numero }}</span>
                                <span :class="$css_report_list" v-if="item.apartamento"> APTO - {{ item.apartamento }}</span>
                                <span :class="$css_report_list" v-if="item.complemento"> - {{ item.complemento }}</span>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-card-actions>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon class="ml-8" @click="showProperty(item.property_id)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar</span>
                                    </v-tooltip>
                                </v-card-actions>
                            </template>            
                        </v-data-table>
                    </v-card>
                    <!-- fim property_owners -->   

                    <!-- facs -->  
                    <v-card class="mt-6" v-if="show.facs && items.facs.length > 0">
                        <div class="text-center text-h6">FACS desse cliente</div>
                         <v-data-table
                            :headers="headers.facs"
                            :items="items.facs"
                            :items-per-page="10"
                            class="elevation-1 table-striped"
                            :footer-props="{
                            'items-per-page-text':'Itens por página',   
                            'items-per-page-options': [5, 10],
                            'items-per-page-all-text': '',
                            'page-text': '{0}-{1} de {2}'
                        }"
                        >
                            <template v-slot:[`item.colaboradores`]="{ item }">
                                <div v-for="itm in item.colaborators" :key="itm.id">
                                    <span :class="$css_report_list">{{ itm.name }} ({{ itm.description }})</span>
                                </div>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-card-actions>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon class="ml-8" @click="editfac(item.id)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                            </v-btn>
                                            
                                            <v-btn icon class="ml-8" @click="showfac(item.id)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar</span>
                                    </v-tooltip>
                                </v-card-actions>
                            </template>            
                        </v-data-table>
                    </v-card> 
                    <!-- fim facs -->   
                </v-col>
                <v-col v-else cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-row dense>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field
                                v-model="item.nome"
                                :rules="[rules.required]"
                                outlined
                                dense
                                required
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Nome</span>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-select
                                v-model="item.user_id"
                                :items="users.data"
                                :rules="[rules.required]"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Colaborador de atendimento</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" xs="2" sm="2" md="4" lg="4" xl="4">
                            <v-tooltip top max-width="650px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="item.property_codigos"
                                        outlined
                                        dense
                                        label="Codigos"
                                        append-icon="mdi-help-circle-outline"
                                        v-on="on"
                                        :class="$css_field"
                                        clearable
                                    >
                                        <template v-slot:label>
                                            <span :class="$css_field">Código de Imoveis (Opcional)</span>
                                        </template>
                                    </v-text-field>
                                </template>
                                <span>
                                    Para cadastrar mais de um codigo basta separar por virgula
                                </span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-btn
                                color="primary"
                                @click="validate"
                                :class="$css_button"
                            >
                                <span>Cadastrar FAC</span>
                            </v-btn>
                        </v-col>
                    </v-row>                                    
                </v-col>    
            </v-row>


    <!-- Edit FAC -->  
            <v-row v-if="item.id" dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.finalidade"
                        :items="items.finalidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.como_conheceu"
                        :items="me.branch.howtoknowitems && me.branch.howtoknowitems.length > 0 ? me.branch.howtoknowitems : items.default_howtoknowitems"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        item-text="description"
                        item-value="description"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Como conheceu?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.classificacao"
                        :items="items.classificacao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        item-text="description"
                        item-value="description"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Classificaçāo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>  
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>      
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email2"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail 2</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>   
            </v-row> 
          
    <!-- End Edit FAC -->  

        </v-form>  



    <!-- Dialog FAC -->
        <v-dialog 
            v-if="fac_show.id"
            v-model="dialog.fac_show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="fac_show.codigo">Código {{fac_show.codigo}}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="editfac(fac_show.id)" class="mr-4">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="openCloseDialogShow('fac')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="fac_show"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
    <!-- End Dialog -->

    <!-- Dialog Property -->
        <v-dialog 
            v-if="property_show.id"
            v-model="dialog.property_show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="property_show.codigo">Código {{property_show.codigo}}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialogShow('property')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-property-component :object="property_show" :permissions="permissions"></show-property-component> 
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
    <!-- End Dialog -->

    </div>
</template>

<script>
import { FINALIDADE_ITEMS, CLASSIFICACAO_ITEMS } from '../configs_fac/configs_fac.js'

import AlertComponent from '../../alert/AlertComponent.vue'
import ShowComponent from '../show/ShowComponent.vue'
import ShowPropertyComponent from '../../properties/show/ShowComponent.vue'

export default {
    components: {
        AlertComponent,
        ShowComponent,
        ShowPropertyComponent
    },

    created() {
        this.setObject()
        this.countFac()
        this.loadUsers()
        this.loadHowtoKnowItems()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        },

        me () {
          return this.$store.state.auth.me
        },

        users() {
            return this.$store.state.users.items    
        },
    }, 

    data () {
        return {
            show: {
                property_owners: false,
                facs: false,
            },

            property_show: {},
            fac_show: {},

            permissions: {
                store_property: true,
                active_property: true,
                update_active_property: true,
                view_owners_others: true,
                colaborator: true
            },

            dialog: {
                property_show: false,
                fac_show: false
            },

            alerts: {
                check_new: {
                    show: '',
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema encontrou o cliente abaixo. Voce poderá confirmar a criaçāo da FAC ou informar outro telefone ou email.']
                },

                limit: {
                    show: '',
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['O limite de cadastro foi atingido. Entre em contato com AYT para migrar de plano ou exclua alguns registros desnecessários.']
                },
            },

            item: {},

            params: {
                howtoknowitems: {
                    paginate: 'false'
                },

                users: {
                    paginate: 'false'
                }
            },

            type_contact: 'Telefone',

            check_new: {
                status: false,
                clients: {}
            },

            headers: {
                clients: [
                    { text: 'NOME', align: 'start', value: 'nome', },
                    { text: 'TELEFONE', value: 'telefone' },
                    { text: 'EMAIL', value: 'email' },
                    { text: 'DETALHES', value: 'details' },
                    { text: 'AÇÕES', value: 'actions', sortable: false },
                ],
                property_owners: [
                    { text: 'CODIGO', align: 'start', value: 'codigo', },
                    { text: 'STATUS', value: 'status' },
                    { text: 'TIPO IMOVEL', value: 'tipo_imovel' },
                    { text: 'ENDEREÇO', value: 'endereco' },
                    { text: 'AÇÕES', value: 'actions', sortable: false },
                ],
                facs: [
                    { text: 'CODIGO', align: 'start', value: 'codigo', },
                    { text: 'STATUS', value: 'status' },
                    { text: 'FINALIDADE', value: 'finalidade' },
                    { text: 'COLABORADORES', value: 'colaboradores' },
                    { text: 'AÇÕES', value: 'actions', sortable: false },
                ]
            },

            total: 0,

            items: {
               contacts: ['Telefone','E-mail'],
               finalidade: FINALIDADE_ITEMS,
               classificacao: CLASSIFICACAO_ITEMS,
               howtoknowitems: [],
               default_howtoknowitems: ['Site'],
               property_owners: [],
               facs: [],
            },
            
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
        }
    },

    methods: {

        showProperty (id) {    
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.property_show = response.data
                            this.dialog.property_show = true
                        })
                                   
        },

        showfac (id) {   
            this.$store.dispatch('loadFac', id) 
                        .then(response => {
                            this.fac_show = response.data
                            this.dialog.fac_show = true
                        })                    
        },

        editfac(id){
            this.$emit('callRedirectEditItem', id)
        },

        showPropertyOwners(data){
            this.items.property_owners = []
            this.show.property_owners = !this.show.property_owners
            if(this.show.property_owners == true){
                this.items.property_owners = data
            }
        },

        showFacs(data){
            this.items.facs = []
            this.show.facs = !this.show.facs
            if(this.show.facs == true){
                this.items.facs = data
            }
        },

        openCloseDialogShow(option){
            if(option == 'fac'){
                this.dialog.fac_show = !this.dialog.fac_show
            }
            if(option == 'property'){
                this.dialog.property_show = !this.dialog.property_show
            }
            
        },

        loadHowtoKnowItems(){
            this.$store.dispatch('loadHowtoKnowItems', this.params.howtoknowitems) 
                        .then(response => {
                            this.items.howtoknowitems = response.data
                        })
                                   
        },

        checkNew(item){
            item.checkNew = 'true'
            this.$store.dispatch('checkNewFac', item)
                        .then(response => {
                            this.check_new.status = true
                            this.check_new.clients = response 
                        })
                        .catch(error => {
                            
                        })
        },

        setFac(client){
            this.item.client_id = client.id
            this.item.nome = client.nome
            this.storeUpdate(this.item)
        },

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },

        countFac(){
            this.$store.dispatch('countFac')
                        .then(response => {
                            this.total = response.meta.total 
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        loadUsers(){
            
            this.$store.dispatch('loadUsers', this.params.users)
        },

        validateCheckNew () {
            if (this.$refs.form.validate()) {
                this.checkNew(this.item)
            }
        },

        backNew(){
            this.check_new.status = false
            this.check_new.facs = {} 
        },
        
        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const action = item.id ? 'updateFac' : 'storeFac' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            if(action == 'storeFac'){
                                this.$store.commit('STORE_FAC', true)
                            }
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        
        }, 
        
    },
}
</script>

<style scoped>

</style>