<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.titulo_publicitario_automatico"
                        :items="items.sim_nao"
                        @change="makeTitle(item.titulo_publicitario_automatico)"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Titulo automatico?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.texto_publicitario_automatico"
                        :items="items.sim_nao"
                        @change="makeText(item.texto_publicitario_automatico)"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Texto automático?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.publicar_imovel"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Publicar o imóvel?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.mapa"
                        :items="items.mapa"
                        outlined
                        dense
                        :rules="[rules.required]"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Mapa</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                        v-model="item.titulo_publicitario"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Titulo Pulicitário</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
             <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.texto_publicitario"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        counter
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Texto publicitário</span>
                        </template>
                    </v-textarea>
                </v-col>
            </v-row>

            <div v-if="item.mapa != 'Nāo exibir'">
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <alert-component :alert="alerts.map"></alert-component>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="item.cep_publicidade"
                            @blur="searchCep(item.cep_publicidade)"
                            :rules="[rules.required]"
                            v-mask="['#####-###']"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">CEP</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                        <v-text-field
                            v-model="item.endereco_publicidade"
                            :rules="[rules.required]"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Endereço</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                            v-model="item.bairro_publicidade"
                            :rules="[rules.required]"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Bairro</span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="2" lg="3" xl="3">
                        <v-text-field
                            v-model="item.cidade_publicidade"
                            :rules="[rules.required]"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Cidade</span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                        <v-text-field
                            v-model="item.estado_publicidade"
                            :rules="[rules.required]"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Estado</span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                        <v-text-field
                            v-model="item.numero_publicidade"
                            type="number"
                            :rules="[rules.required]"
                            outlined
                            dense
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Número</span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                            v-model="localidade"
                            :items="items.idLocalidade"
                            outlined
                            dense
                            item-text="nombre"
                            item-value="id"
                            :class="$css_field"
                            clearable
                            @change="setLocalidade"
                        >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro Imovel Web</span>
                        </template>
                        </v-select>
                    </v-col>
                </v-row>
            </div>

            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                   <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>
import { MAPA_ITEMS, SIM_NAO_ITEMS } from '../config_properties/config_properties.js'
import AlertComponent from '../../alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    data () {
        return {
            alerts: {
                map: {
                    show: 'null',
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['O endereço abaixo será usado para exibiçāo do mapa nas opções "Área aproximada" ou "Endereço exato"']
                },
            },
                
            item: {},

            items: {
                mapa: MAPA_ITEMS,
                sim_nao: SIM_NAO_ITEMS,
                idLocalidade: []
            },

            localidade: '',
  
            rules: {
                required: value => !!value || 'Obrigatório',
                min_value:  v => (v && v.length >= 5) || 'O valor precisa ser maior que zero (0)',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
        }
    },

    methods: {
        setObject(){
            this.item = this.object 
            this.setIdLocalidade()
        },

        setLocalidade(){
            if(this.localidade != ''){
                const local = this.items.idLocalidade.filter( model => model.id == this.localidade)
                if(local && local.length > 0){
                    this.item.json_id = local[0].id
                    this.item.json_nombre = local[0].nombre
                    this.item.json_nombreCompleto = local[0].nombreCompleto
                }
            }
        },

        setIdLocalidade(){
            if(this.item.json_local_params && this.item.json_local_params != null){
                if(Array.isArray(this.item.json_local_params) == true){
                    const city = this.item.json_local_params[2] ? this.item.json_local_params[2] : ''
                    if(city.id){
                        const paramsID = {}
                        paramsID.idLocalidade = city.id
                        this.$store.dispatch('getLocalidades', paramsID)
                        .then(response => {
                            this.items.idLocalidade = response.localidades   
                            this.setVarLocalidade()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })  
                    }
                }
            }


        },

        setVarLocalidade () {
            if(this.item.json_localidade && this.item.json_localidade != ''){
                this.localidade = this.item.json_localidade.id
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$store.dispatch('updateProperty', item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

        searchCep(value){
            //this.setNull()
            this.item.endereco_publicidade = 'Loading...'
            this.item.bairro_publicidade = 'Loading...'
            this.item.cidade_publicidade = 'Loading...'
            this.item.estado_publicidade = 'Loading...'

            if(!value) return ''
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setAddress(address){
            this.item.endereco_publicidade = address.logradouro
            this.item.bairro_publicidade = address.bairro
            this.item.cidade_publicidade = address.localidade
            this.item.estado_publicidade = address.uf
        },

        makeTitle(option) {
            if(option == 'Sim'){
                var title = ''
                if(this.item.tipo_imovel != ''){
                    title += this.item.tipo_imovel
                }
                if(this.item.sub_tipo_imovel != '' && this.item.sub_tipo_imovel != null){
                    title += ' (' + this.item.sub_tipo_imovel + ')'
                }
                if(this.item.setor != ''){
                    title += ' para ' + this.item.setor
                }
                if(this.item.places[0].place && this.item.places[0].place != ''){
                    title += ' no bairro ' + this.item.places[0].place
                }
                if(this.item.dormitorios > 0){
                    title += ', ' + this.item.dormitorios + ' dorm(s)'
                }
                if(this.item.suites > 0){
                    title += ', ' + this.item.suites + ' suite(s)'
                }
                if(this.item.vagas > 0){
                    title += ', ' + this.item.vagas + ' vaga(s)'
                }
                if(this.item.area > 0){
                    title += ', ' + this.item.area + ' m²'
                }
                this.item.titulo_publicitario = title
            }
        },

        makeText(option){
            if(option == 'Sim'){
                var title = ''
                if(this.item.tipo_imovel != ''){
                    title += this.item.tipo_imovel
                }
                if(this.item.sub_tipo_imovel != '' && this.item.sub_tipo_imovel != null){
                    title += ' (' + this.item.sub_tipo_imovel + ')'
                }
                if(this.item.setor != ''){
                    title += ' para ' + this.item.setor
                }
                if(this.item.places[0].place && this.item.places[0].place != ''){
                    title += ' no bairro ' + this.item.places[0].place
                }
                if(this.item.dormitorios > 0){
                    title += ', ' + this.item.dormitorios + ' dorm(s) '
                }
                if(this.item.suites > 0){
                    title += ', ' + this.item.suites + ' suite(s) '
                }
                if(this.item.vagas > 0){
                    title += ', ' + this.item.vagas + ' vaga(s) '
                }
                if(this.item.area > 0){
                    title += ', ' + this.item.area + ' m²'
                }
                if(this.item.private_areas && this.item.private_areas.length > 0){
                    title += "\n" + "Área privativa: "
                    title += this.converteObjectArrayToString(this.item.private_areas)
                }
                if(this.item.infrastructures && this.item.infrastructures.length > 0){
                    title += "\n" + "Infra: "
                    title += this.converteObjectArrayToString(this.item.infrastructures)
                }
                if(this.item.recreations && this.item.recreations.length > 0){
                    title += "\n" + "Lazer: "
                    title += this.converteObjectArrayToString(this.item.recreations)
                }
                if(this.item.perto_de && this.item.perto_de.length > 0){
                    title += "\n" + "Proximidades: "
                    title += this.converteArrayToString(this.item.perto_de)
                }
                if(this.item.garantia_locacao && this.item.garantia_locacao.length > 0){
                    title += "\n" + "Garantia de locaçāo: "
                    title += this.converteArrayToString(this.item.garantia_locacao)
                }

                this.item.texto_publicitario = title
            }
        },

      
    },
}
</script>