<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 

        >
            <v-tab href="#tab-1" class="caption"> Principal</v-tab>
            <v-tab href="#tab-2" v-show="item.id" class="caption">Plantas</v-tab>
            <v-tab href="#tab-3" v-if="object.torre_unica != 'Sim'" v-show="item.id" class="caption">Torres</v-tab>
            <v-tab href="#tab-4" v-show="item.id" class="caption">Posições</v-tab>
            <v-tab href="#tab-5" v-show="item.id" class="caption"> Infra </v-tab>
            <v-tab href="#tab-6" v-show="item.id" class="caption"> Lazer </v-tab>
            <v-tab href="#tab-7" v-show="item.id" class="caption"> Fotos </v-tab>
            <v-tab href="#tab-8" v-show="item.id" class="caption"> Colaboradores </v-tab>
            <v-tab href="#tab-9" v-show="item.id" class="caption"> Locais </v-tab>
            <v-tab href="#tab-10" v-show="item.id" class="caption"> Status </v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <plan-component :object="plans" @callLoadItem="loadItem"></plan-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <tower-component :object="towers" @callLoadItem="loadItem"></tower-component>
                </v-card>    
            </v-tab-item>  

            <v-tab-item value="tab-4">
                <v-card flat class="pa-8">
                    <position-component :object="positions" @callLoadItem="loadItem"></position-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-5">
                <v-card flat class="pa-8">
                    <property-item-component :object="infrastructures" @callLoadItem="loadItem"></property-item-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-6">
                <v-card flat class="pa-8">
                    <property-item-component :object="recreations" @callLoadItem="loadItem"></property-item-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-7">
                <v-card flat class="pa-8">
                    <drop-zone-component-new-2 id="118" :object="photos" @callLoadItem="loadItem" :key="key.dropzone"></drop-zone-component-new-2>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-8">
                <v-card flat class="pa-8">
                    <colaborator-component :object="colaborators" @callLoadItem="loadItem"></colaborator-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-9">
                <v-card flat class="pa-8">
                    <place-component :object="places" @callLoadItem="loadItem"></place-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-10">
                <v-card flat class="pa-8">
                    <status-component :object="item" @callLoadItem="loadItem"></status-component>
                </v-card>    
            </v-tab-item>                

        </v-tabs-items>

    </div>    
</template>


<script>
import { COLABORATOR_DESCRIPTIONS } from '../config_condominiums/config_condominiums.js'

import MainComponent from './MainComponent.vue'
import PlanComponent from '../../plans/PlanComponent.vue'
import TowerComponent from '../../towers/TowerComponent.vue'
import PositionComponent from '../../positions/PositionComponent.vue'
import PropertyItemComponent from '../../property_items/PropertyItemComponent.vue'
import DropZoneComponent from '../../dropzone/DropZoneComponent.vue'
import ColaboratorComponent from '../../colaborators/ColaboratorComponent.vue'
import PlaceComponent from '../../places/PlaceComponent.vue'
import StatusComponent from './StatusComponent.vue'
import DropZoneComponentNew2 from '@/components/dropzone/DropZoneComponentNew2.vue'
import DropZoneV18Component from '@/components/dropzone/DropZoneV18Component.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
        MainComponent,
        PlanComponent,
        TowerComponent,
        PositionComponent,
        PropertyItemComponent,
        DropZoneComponent,
        ColaboratorComponent,
        PlaceComponent,
        StatusComponent,
        DropZoneComponentNew2,
        DropZoneV18Component
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    data () {
        return {
            active_tab: 'tab-1',
            item: {

            },

            key: {
                dropzone: 3,
            },

            plans: {
                item_id: this.object.id,
                items: this.object.plans
            },

            towers: {
                item_id: this.object.id,
                items: this.object.towers
            },

            positions: {
                item_id: this.object.id,
                items: this.object.positions
            },

            infrastructures: {
                model: "condominiums",
                model_property: "infrastructures",
                order: "order",
                method: "storeInfrastructure",
                tipos: [this.object.tipo_condominio],
                regioes: [this.object.regiao],
                item_id: "",
                items: [],     
            },

            recreations: {
                model: "condominiums",
                model_property: "recreations",
                order: "order",
                method: "storeRecreation",
                tipos: [this.object.tipo_condominio],
                regioes: [this.object.regiao],
                item_id: "",
                items: [],
            },

            photos:{
                model: 'condominiums',
                item_id: '',
                items: '',
                watermark: ''
            },

            colaborators: {
                model: "condominiums",
                descriptions: COLABORATOR_DESCRIPTIONS,
                item_id: "",
                items: []     
            },

            places: {
                model: "condominiums",
                item_id: "",
                cidade: "",
                estado: "",
                items: []     
            },
        }
    },

    methods: {
        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object

            // plans
            this.plans.item_id = this.item.id
            this.plans.items = this.item.plans

            // towers
            this.towers.item_id = this.item.id
            this.towers.items = this.item.towers

            // positions
            this.positions.item_id = this.item.id
            this.positions.items = this.item.positions

            // set infrastructures
            this.infrastructures.item_id = this.object.id
            this.infrastructures.items = this.object.infrastructures

            // set recreations
            this.recreations.item_id = this.object.id
            this.recreations.items = this.object.recreations

            // photos
            this.photos.item_id = this.object.id
            this.photos.items = this.object.photos
            this.photos.watermark = this.tenant[0].watermark

            // colaborators
            this.colaborators.item_id = this.object.id
            this.colaborators.items = this.object.colaborators

            // places
            this.places.item_id = this.object.id
            this.places.estado = this.object.estado
            this.places.items = this.object.places
        },
        
    },
}
</script>

<style scoped>

</style>