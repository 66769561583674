<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">  
                    <v-file-input 
                        v-model="file"
                        :rules="[rules.required]"
                        :accept="object.extensions"
                        show-size 
                        counter 
                        :label="object.label_extensions"
                        outlined
                        dense
                        clearable
                        :class="$css_field"
                        :multiple="object.multiple"
                        :prepend-icon="object.icon ? object.icon : 'mdi-paperclip'"
                    ></v-file-input>
                </v-col>
                <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5" v-if="object.descriptions && object.descriptions.length > 0">
                    <v-select
                        v-model="description"
                        :items="object.descriptions"
                        label="Descriçāo"
                        outlined
                        dense
                        :class="$css_field"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5" v-if="object.description_text && object.description_text == true">
                    <v-text-field
                        v-model="description"
                        label="Descrição"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2" v-if="object.outros && object.outros == true">
                    <v-text-field
                        v-model="description_medium"
                        label="obs (opcional)"
                        outlined
                        dense
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col>  

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        Adicionar
                    </v-btn>
                </v-col> 
                
            </v-row>
        </v-form> 
            <v-row v-if="object.items">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                    <v-card
                        class="mx-auto"
                        outlined
                    >
                        <v-data-table
                            :headers="headers"
                            :items="object.items"
                            :items-per-page="10"
                            sort-by="id"
                            class="elevation-1 table-striped"
                        >
                            <template v-slot:[`item.description`]="{ item }">
                                <span :class="$css_report_list">
                                    {{ item.description }}
                                    <span class="ml-2" v-if="item.description_medium">({{ item.description_medium }})</span>
                                </span> 
                            </template>
  
                            <template v-slot:[`item.photo`]="{ item }">
                                <v-avatar
                                    tile
                                    width="100px"
                                    height="80px"
                                >
                                    <v-img 
                                        v-if="item.file && item.file != ''"
                                        :src="item | urlUpload"
                                        :lazy-src="item | urlUpload"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                            >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-avatar>     
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-card-actions>
                                    <v-tooltip left v-if="item.file">
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :href="item | urlUpload" target="_blank">  
                                                <v-icon v-bind="attrs" v-on="on">mdi-download</v-icon>                           
                                            </a>
                                        </template>
                                        <span>DOWNLOAD</span>
                                    </v-tooltip>   

                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="fileDestroy(item)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>EXCLUIR</span>
                                    </v-tooltip>
                                </v-card-actions>     
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense class="mt-4" v-if="object.tabs">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <span v-if="object.links">
                        <span v-for="item in object.links" :key="item.description">
                            <v-btn
                                dark
                                color="black"
                                large
                                @click="actionEmitLinks(item.action, item.value)"
                            >
                                {{ item.description }}
                            </v-btn>
                        </span>
                    </span>
                    
                    <v-btn
                         v-if="object.tabs.before"
                        dark
                        color="grey"
                        large
                        @click="changeStep(object.tabs.before)"
                    >
                        <v-icon>mdi-menu-left</v-icon>
                        Back
                    </v-btn>

                    <v-btn
                        v-if="object.tabs.next"
                        dark
                        color="primary"
                        large
                        @click="changeStep(object.tabs.next)"
                    >
                        Next
                    <v-icon>mdi-menu-right</v-icon>
                    </v-btn>
                </v-col>
           </v-row>    


    </div>
</template>

<script>

    export default {
        created(){
            this.check()
        },

        props: {
            object: {
                require: true,
            },
        },

        computed: {
            me () {
                return this.$store.state.auth.me
            },
        },

        data () {
            return {
                file: {},
                description: '',
                description_medium: '',
                description_medium2: '',

                rules: {
                    required: value => !!value || 'Required.',
                },
                headers: [],
            }
        },

        methods:{   

            check(){
                if(this.object.model == 'users'){
                    this.headers = [
                        { text: 'FOTO', align: 'start', value: 'photo', },
                        { text: 'AÇÕES', value: 'actions', sortable: false },
                    ]
                } else if (this.object.order && this.object.order == 'true'){    
                    this.headers = [
                        { text: 'FOTO', align: 'start', value: 'photo', },
                        { text: 'ORDEM', align: 'start', value: 'description_medium', },
                        { text: 'AÇÕES', value: 'actions', sortable: false },
                    ]
                }else{
                    this.headers = [
                        { text: 'DESCRIÇĀO', align: 'start', value: 'description', },
                        { text: 'AÇÕES', value: 'actions', sortable: false },
                    ]
                }
            },

            setObject(){
                this.items = this.object.items
            },

            changeStep(step){
                this.$emit('callChangeStep',step)
            },

            validate () {
                if (this.$refs.form.validate()) {                   
                    this.fileSubmit()
                }
            },
            
            fileSubmit(){
                const formData = new FormData()
                formData.append('model', this.object.model)  
                formData.append('item_id', this.object.item_id)  
                
                if(this.object.description != undefined){
                    formData.append('description', this.object.description)     
                }
                if(this.description != undefined && this.description != ''){
                    formData.append('description', this.description)     
                }
                if(this.description_medium != undefined && this.description_medium != ''){
                    formData.append('description_medium', this.description_medium)     
                }
                if(this.description_medium2 != undefined && this.description_medium2 != ''){
                    formData.append('description_medium2', this.description_medium2)     
                }
               
                //formData.append('file', this.file) 
                if(Array.isArray(this.file)){
                    for( var i = 0; i < this.file.length; i++ ){
                        let file = this.file[i];
                        formData.append('file[' + i + ']', file)
                    }
                }else{
                    formData.append('file[0]', this.file)
                } 
                
                
                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]); 
                }
                
            
                this.$store.dispatch('storeUpload', formData)
                            .then(response => {
                                this.reset()
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Upload Successfully!')
                                

                                if(this.object.model == 'users' && this.me.id == this.object.item_id){
                                    this.refreshMe()
                                }
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
            },

            reset(){
                this.$refs.form.reset()
                //this.file.description = null
                //this.file = {}
            },

            confirmDestroy(file) {
                this.$snotify.error(`Are you sure to delete this file? ${file.description}`, 'Confirmation', {
                    id: 1,
                    timeout: 10000,
                    showProgressBar: true,
                    closeOnClick: false,
                    buttons: [
                        {text: 'No', action: null},
                        {text: 'Yes', action: () => { 
                            this.fileDestroy(file)
                            //this.$snotify.remove()
                        }},
                    ] 
                })
            },

             fileDestroy(file){
                this.$store.dispatch('destroyUpload', file.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                if(this.object.model == 'users' && this.me.id == this.object.item_id){
                                    this.refreshMe()
                                }
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Successfully deleted')
                            )
            },

            actionEmitLinks(action, value){
                this.$emit(action,value)
            },

            refreshMe(){
                if(this.me.id == this.object.item_id){
                    this.$store.dispatch('refreshMe', this.object.item_id)
                }
            }
            
        }    
    }
</script>

<style scoped>
    .panel-footer {
        position: absolute;
        padding: 0%;
        margin: 0%;
        margin-top: 0px;
        z-index: 1000;
    }
</style>