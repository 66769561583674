import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'properties'


export default {
    state: {
        items: {
            data: []
        },

        store: false
    },
    
    mutations: {
        LOAD_PROPERTIES (state, data) {
            state.items = data
        },

        STORE_PROPERTIES (state, status) {
            state.store = status
        }
    },

    actions: {
        loadProperties(context, params) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then( response =>
                        {
                            context.commit('LOAD_PROPERTIES', response.data)
                        }
                )
                //.catch( errors => console.log(errors))
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        window.location.href = "login";
                    }

                })
                .finally(() => context.commit('PRELOADER', false))
        },

        loadPromisseProperties(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        loadPropertiesExcel(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        storeProperty(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(
                        response => resolve(response.data
                    ))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
        
        updateProperty(context, params){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        loadProperty(context, id) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        destroyProperty(context, id){
            
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                    //.finally(() => context.commit('PRELOADER', false))    
            })
            
        },

        // add methods
        newProperty(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}propertynew`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        copyProperty(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}copyproperty`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        countProperties(context) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        propertyEmail(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}propertyemails`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

    },

    getters: {

    }

}