<template>
    <div>
        <v-form ref="form" v-if="items.favorites.length > 0">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.to_name"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome do destinatário</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.to_email"
                        :rules="[rules.required, rules.email]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail do destinátario</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                        dark
                        large
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        Enviar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="mx-auto"
                        outlined
                    >
                        <v-data-table
                            :headers="headers"
                            :items="items.favorites"
                            :options.sync="options"
                            class="elevation-1 table-striped caption"
                            :footer-props="{
                                'items-per-page-text':'Itens por página',
                                'items-per-page-options': [5, 10],
                                'items-per-page-all-text': '',
                                'page-text': '{0}-{1} de {2}'
                            }"
                        >   
                            <template v-slot:[`item.foto`]="{ item }">
                                <v-avatar
                                    class="ma-2"
                                    color="#BDBDBD"
                                    tile
                                    width="200px"
                                    height="150px"
                                    @click.prevent="showCarousel(item)"
                                >
                                    <v-img 
                                        v-if="item.photos_total && item.photos_total.length > 0"
                                        :src="item.photos_total[0] | urlUpload"
                                        :lazy-src="item.photos_total[0] | urlUpload"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                            >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                    <v-icon v-else large dark>
                                        mdi-camera
                                    </v-icon>
                                </v-avatar>
                            </template>
                            
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip
                                    :color="item.status | statusProperty"
                                    label
                                    small
                                >
                                    {{ item.status }}
                                </v-chip> 
                            </template>

                            <template v-slot:[`item.detalhes`]="{ item }">
                                <div class="mb-2 mt-2">
                                    <span>
                                        <v-chip
                                            label
                                            small
                                            color="blue lighten-5" 
                                        >
                                        <span style="font-size:12px">{{ item.codigo }}</span> 
                                        </v-chip>   
                                    </span>
                                    <span class="ml-2">
                                        <v-chip
                                            :color="item.status | statusProperty"
                                            label
                                            small
                                        >
                                            {{ item.status }}
                                        </v-chip> 
                                    </span>
                                    
                                    <span class="ml-2 black--text font-weight-medium">
                                        {{ item.tipo_imovel }}
                                        <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span>
                                    </span>
                                </div>
                                <div>
                                    {{ item.endereco }}
                                    <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                    <span v-if="item.andar">  - {{ item.andar }} andar </span>
                                    <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                                    <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                    <!--span v-if="item.position && item.position.nome != ''"> - Posiçāo: {{ item.position.nome }} </span-->
                                    <span v-if="item.bairro">  - {{ item.bairro }} </span>
                                    <span v-if="item.cidade">  - {{ item.cidade }} </span>
                                    <span v-if="item.estado">  - {{ item.estado }} </span>
                                    <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                                </div> 
                                <div class="mt-3">
                                    <span v-if="item.area">
                                        <v-chip
                                            label
                                            small
                                            color="blue-grey lighten-5" 
                                        >
                                        <span>Área {{ item.area }} m²</span> 
                                        </v-chip>
                                    </span>
                                    <span class="ml-2" v-if="item.dormitorios">
                                        <v-chip
                                            label
                                            small
                                            color="blue-grey lighten-5" 
                                        >
                                        <span>{{ item.dormitorios }} dorm</span> 
                                        </v-chip>
                                    </span>
                                    <span class="ml-2" v-if="item.banheiros">
                                        <v-chip
                                            label
                                            small
                                            color="blue-grey lighten-5" 
                                        >
                                        <span>{{ item.banheiros }} banheiros</span> 
                                        </v-chip>
                                    </span>
                                    <span class="ml-2" v-if="item.vagas">
                                        <v-chip
                                            label
                                            small
                                            color="blue-grey lighten-5" 
                                        >
                                        <span>{{ item.vagas }} vagas</span> 
                                        </v-chip>
                                    </span>
                                </div>
                                <div class="mt-3">
                                    <v-text-field
                                        v-model="comentarios[item.count]"
                                        label="message"
                                        outlined
                                        dense
                                        :class="$css_field"
                                    >
                                        <template v-slot:label>
                                            <span :class="$css_field">Observações (opcional)</span>
                                        </template>
                                    </v-text-field>
                                </div>

                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <div v-else class="text-center">
            Nāo há imoveis nos favoritos. Clique no icone "coraçāo" na lista de imóveis.
        </div>
    </div>
</template>

<script>

export default {
    created(){
        this.setFavourites()
    },

    computed: {
        favorites () {
            return this.$store.state.favorites.items.data
        }
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            this.setFavourites()
        },
    },    

    data() {
        return {
            comentarios: [],

            items: {
                favorites: []
            },

            item: {
                to_name: '',
                to_email: '',
                properties: []
            },

            rules: {
                required: value => !!value || 'Obrigatório.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'FOTO', value: 'foto' , sortable: false},
                { text: 'DETALHES', value: 'detalhes' , sortable: false},  
            ],

            options: {
                page: 1,
                itemsPerPage: 20,
            },
        }
    },

    methods: {
        setFavourites(){
            this.items.favorites = []

            if(this.object && this.object.id != ''){
                    this.items.favorites.push(this.object)
            }else if(this.favorites && this.favorites.length > 0){
                var count = 0
                this.favorites.forEach(element => {
                    element.count = count 
                    //console.log(element)
                    this.items.favorites.push(element)
                    count++
                });
            }    
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.submit()
            }
        },

        submit(){
            this.item.properties = []
            this.items.favorites.forEach((element) => {
                const property = {}
                property.id = element.id
                property.message = this.comentarios[element.count]
                this.item.properties.push(property)
            })

            //console.log(this.item)
            this.$store.dispatch('propertyEmail', this.item) 
                        .then(response => {
                            this.$snotify.success('E-mail enviado sucesso!')
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                        })
        }
    }
}
</script>
