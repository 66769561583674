<template>
    <div>
        <v-container>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="rounded-0"
                        outlined
                        id="table_border"
                    >
                        <v-simple-table dense class="rounded-0">
                            <thead fixed-header>
                                <tr>
                                    <th>
                                        <v-avatar
                                            class="ma-0"
                                            tile
                                            width="100px"
                                            height="100px"
                                        >
                                            <v-img 
                                                v-if="branch_logo && branch_logo != ''"
                                                :src="branch_logo"
                                                :lazy-src="branch_logo"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                    >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="lighten-5"
                                                    ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <v-icon v-else large dark>
                                                mdi-camera 
                                            </v-icon>
                                        </v-avatar>
                                    </th>
                                    <th class="text-h5 black--text">
                                        {{ object.titulo_publicitario }}
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                   </v-card>                                    
                </v-col>    
            </v-row>
            <v-row dense v-if="object.photos_total && object.photos_total.length > 0">
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-simple-table class="rounded-0 pa-1" id="table_border">
                        <tbody>
                            <tr>
                                <td>  
                                    <v-img
                                        width="200px"
                                        position="center"
                                        :src="object.photos_total[0].urlremoto"
                                        :lazy-src="object.photos_total[0].urlremoto"
                                    >
                                    </v-img>
                                </td>
                                <td>
                                    <v-img
                                        width="200px"
                                        position="center"
                                        :src="object.photos_total[1].urlremoto"
                                        :lazy-src="object.photos_total[1].urlremoto"
                                    >
                                    </v-img>        
                                </td>
                                <td>
                                    <v-img
                                        width="200px"
                                        position="center"
                                        :src="object.photos_total[2].urlremoto"
                                        :lazy-src="object.photos_total[2].urlremoto"
                                    >
                                    </v-img>        
                                </td>
                            </tr>
                            <tr>
                               <td colspan="3">&nbsp;</td>     
                            </tr>    
                            <tr>
                                <td>  
                                    <v-img
                                        width="200px"
                                        position="center"
                                        :src="object.photos_total[3].urlremoto"
                                        :lazy-src="object.photos_total[3].urlremoto"
                                    >
                                    </v-img>
                                </td>
                                <td>
                                    <v-img
                                        width="200px"
                                        position="center"
                                        :src="object.photos_total[4].urlremoto"
                                        :lazy-src="object.photos_total[4].urlremoto"
                                    >
                                    </v-img>        
                                </td>
                                <td>
                                    <v-img
                                        width="200px"
                                        position="center"
                                        :src="object.photos_total[5].urlremoto"
                                        :lazy-src="object.photos_total[5].urlremoto"
                                    >
                                    </v-img>        
                                </td>
                            </tr>
                            </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="text-center" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="pa-2 rounded-0"
                        outlined
                        id="table_border"
                    >
                        <span class="text-h5 black--text"> Código {{ object.codigo }} </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="text-center" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="pa-2 rounded-0"
                        outlined
                        id="table_border"
                    >
                        <span class="text-h6 black--text">
                            <span v-if="object.dormitorios && object.dormitorios > 0"> Dormitórios: {{ object.dormitorios }} <span v-if="object.suites">({{ object.suites }} suites)</span> </span>
                            <span class="ml-3" v-if="object.banheiros && object.banheiros > 0"> Banheiros: {{ object.banheiros }}</span>
                            <span class="ml-3" v-if="object.vagas && object.vagas > 0"> Vagas: {{ object.vagas }}</span>
                            <span class="ml-3" v-if="object.area && object.area > 0"> Área: {{ object.area }} m²</span>
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense v-if="object.valor_venda > 0 || object.valor_locacao > 0">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="pa-2 rounded-0 text-center"
                        outlined
                        id="table_border"
                    >
                    <span class="text-h5" v-if="object.valor_venda && object.valor_venda > 0">Venda {{ object.valor_venda | toCurrency }}</span>      
                    <span  v-if="object.valor_venda && object.valor_venda > 0" class="mr-2"></span>

                    <span class="text-h5" v-if="object.valor_locacao && object.valor_locacao > 0">Locaçāo {{ object.valor_locacao | toCurrency }}</span> 
                    <span v-if="object.valor_locacao && object.valor_locacao > 0" class="mr-2"></span>

                    <span class="text-h5 ml-3" v-if="object.valor_condominio">Condomínio {{ object.valor_condominio | toCurrency }}</span> 
                    
                    </v-card>
                </v-col>
            </v-row>                             
        </v-container>
    </div>
</template>

<script>

export default {    
    props: {
        object: {
            require: true,
        },

        branch_logo: {
            require: false,
        }
    },

    data () {
        return {
            modelo: 1,
            img:{
                height: '200px'
            }
        }   
    },

}
</script>