import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'placeitems'

export default {
    state: {
        items: {
            data: []
        }
    },

    mutations: {
        LOAD_PLACE_ITEMS (state, data) {
            state.items = data
        }
    },

    actions: {
        loadPlaceItems(context, params) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then( response =>
                        {
                            context.commit('LOAD_PLACE_ITEMS', response.data)
                        }
                )
                //.catch( errors => console.log(errors))
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        window.location.href = "login";
                    }

                })
                .finally(() => context.commit('PRELOADER', false))
        },
    },

    getters: {

    }

}