<template>
    <div>
        <div v-if="$vuetify.breakpoint.mobile">
            <v-row v-for="item in object.data" :key="item.id">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        outlined
                        class="pa-2"
                    >
                        <v-row dense>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <carousel-component :object="item"></carousel-component>
                            </v-col>    
                        </v-row>
                        <v-row dense>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span>
                                    <v-chip
                                        label
                                        small
                                        color="blue lighten-5" 
                                    >
                                    <span style="font-size:12px">{{ item.codigo }}</span> 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusPAI"
                                        label
                                        small
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                            </v-col>  
                        </v-row> 
                        <v-row dense>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <div :class="$css_report_list">{{ item.nome }}</div> 
                                <span  style="font-size:12px">
                                    {{ item.endereco }}
                                    <span v-if="item.numero">, {{ item.numero }} </span>
                                </span>
                            </v-col>
                        </v-row>
                        

                        <v-row dense>
                            <v-col class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-card-actions class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="showCarousel(item)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-camera</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Slide Show</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="showItem(item.id)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top v-if="permissions.update == true">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>

                                    <v-tooltip top  v-if="me.profile == 'Master' || me.profile == 'Gerente'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-8">
                                                <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip>
                                </v-card-actions>  
                            </v-col>    
                        </v-row>

                    </v-card>
                </v-col>    
            </v-row>

            <v-row v-if="object.meta && object.meta.total > 0">
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn
                        v-if="object.meta.to > 1 && object.meta.total > this.options.itemsPerPage"
                        depressed
                        class="mt-2 mb-3 rounded-0" 
                        color="error"
                        @click="linkPagination(object.meta.to - 1)"
                        block
                    >
                        <v-icon left>
                            mdi-skip-previous
                        </v-icon>
                        Anterior
                    </v-btn>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn
                        v-if="object.meta.to < object.meta.total"
                        depressed
                        color="primary"
                        class="mt-2 mb-3 rounded-0"
                        @click="linkPagination(object.meta.to + 1)"
                        block
                    >
                        Próximo
                        <v-icon left class="ma-1">
                            mdi-skip-next
                        </v-icon>
                    </v-btn>
                </v-col>    
            </v-row>
        </div>


        <v-row class="mt-4" v-if="!$vuetify.breakpoint.mobile">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    
                    <template v-slot:[`item.foto`]="{ item }">
                        <v-avatar
                            class="ma-2"
                            color="#BDBDBD"
                            tile
                            width="100px"
                            height="80px"
                            @click.prevent="showCarousel(item)"
                        >
                            <v-img 
                                v-if="item.photos && item.photos.length > 0"
                                :src="item.photos[0] | urlUpload"
                                :lazy-src="item.photos[0] | urlUpload"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-icon v-else large dark>
                                mdi-camera
                            </v-icon>
                        </v-avatar>
                    </template>
                    
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            :color="item.status | statusPAI"
                            label
                            small
                            :class="$css_report_list"
                        >
                            {{ item.status }}
                        </v-chip> 
                    </template>


                    <template v-slot:[`item.codigo`]="{ item }">
                        <v-chip
                            label
                            small
                            color="blue lighten-5" 
                        >
                            <span style="font-size:12px">{{ item.codigo }}</span> 
                        </v-chip> 
                    </template>
                    
                    <template v-slot:[`item.nome`]="{ item }">
                        <span :class="$css_report_list">{{ item.nome }}</span> 
                    </template>

                    <template v-slot:[`item.endereco`]="{ item }">
                        <span :class="$css_report_list">{{ item.endereco_completo }}</span> 
                    </template>
                    

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="showCarousel(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-camera</v-icon>
                                    </v-btn>
                                </template>
                                <span>Slide Show</span>
                            </v-tooltip>
                            
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="showItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar</span>
                            </v-tooltip>
                            
                            <v-tooltip top v-if="permissions.update == true">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>

                             <v-tooltip v-if="me.profile == 'Master' || me.profile == 'Gerente'" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog_carousel"
            width="800"
            overlay-opacity="0.8"
        >
            <v-card class="d-print-none">
                <div class="text-right">
                    <v-btn icon text class="mr-8" @click="openCloseDialogCarousel()">
                        fechar <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <carousel-component :object="item" @callOpenCloseCarousel="openCloseDialogCarousel"></carousel-component>
            </v-card> 
        </v-dialog>

    </div>
</template>

<script>
import CarouselComponent from '../../carousel/CarouselComponent.vue'

export default {

    components: {
        CarouselComponent
    },

    props: {
        object: {},

        filters: {},

        permissions: {}
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    }, 

    data () {
        return {
            dialog_carousel: false,
            item: {},

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'FOTO', value: 'foto' , sortable: false},
                { text: 'CODIGO', value: 'codigo' , sortable: false}, 
                { text: 'STATUS', value: 'status' , sortable: false},  
                { text: 'NOME', value: 'nome' , sortable: false},
                { text: 'ENDEREÇO', value: 'endereco' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    filters: {
        userProfileColor (value) {
            if(!value) return ''
            if(value == 'Gerente') return 'blue lighten-4'
            else if(value == 'Master') return 'orange lighten-4'
            else if(value == 'Corretor') return 'green lighten-4'
            else return ''
        },
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },

        showItem(id){
            this.$emit('callShowItem', id)
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyCondominium', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Exclusāo')
                            this.$emit('callLoadItems')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },

        showCarousel(item){
            this.item = item
            this.openCloseDialogCarousel()
        },

        openCloseDialogCarousel(){
            this.dialog_carousel = !this.dialog_carousel
        },

        linkPagination(page){
            this.options.page = page 
            this.loadItems();
        }
    },
}
</script>
