<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.tipo_imovel"
                        :items="items.tipos_imovel"
                        item-text="tipo"
                        item-value="tipo"
                        outlined
                        dense
                        multiple
                        clearable
                        :class="$css_field"
                        @change="searchSubTipoImovel"
                        required
                        :rules="[rules.multilple_required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.tipo }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            :class="$css_field"
                            >
                                (+{{ params.tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.sub_tipo_imovel"
                        :items="items.sub_tipo_imovel"
                        outlined
                        dense
                        clearable
                        multiple
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Sub Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div :class="$css_field" v-if="index === 0">
                                <span>{{ item }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                                (+{{ params.sub_tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.dormitorios"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Dormitório</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.banheiros"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Banheiros</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.vagas"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vagas</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área Minima</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area_total"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área Total Minima</span>
                        </template>
                    </v-text-field>
                </v-col>


                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_minimo"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                        clearable
                        :rules="[rules.required, rules.min]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Mínimo</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_maximo"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                        clearable
                        :rules="[rules.required, rules.min]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Máximo</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.aceita_permuta"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Aceita permuta?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.aceita_financiamento"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Aceita financiamento?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.em_condominio"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em condominio?</span>
                        </template>
                    </v-select>
                </v-col>

            </v-row>

            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                   <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>
import {SIM_NAO_ITEMS, TIPO_IMOVEL_PROPERTY_ITEMS} from '@/components/properties/config_properties/config_properties.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },

        fac_id: {
            require: true,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        }
    },

    data () {
        return {
            params: {},

            item: {},
            
            items: {
                tipos_imovel: TIPO_IMOVEL_PROPERTY_ITEMS,
                sub_tipo_imovel: [],
                sim_nao: SIM_NAO_ITEMS
            },
  
            rules: {
                required: value => !!value || 'Obrigatório',
                multilple_required: value => value.length > 0 || 'Obrigatório',
                min:  v => (v && v.length > 5) || 'Maior que R$ 100',
            },
        }
    },

    methods: {
        setObject(){
            if(this.object.length > 0){
                this.item = this.object[0]
                if(this.item.tipo_imovel != ''){
                    this.params.tipo_imovel = this.converteStringToArray(this.item.tipo_imovel,',')
                    this.searchSubTipoImovel()
                }
                if(this.item.sub_tipo_imovel != ''){
                    this.params.sub_tipo_imovel = this.converteStringToArray(this.item.sub_tipo_imovel,',')
                }
            }
        },

        searchSubTipoImovel(){
            this.items.sub_tipo_imovel = []
            this.params.tipo_imovel.forEach(element => {
                var sub = this.items.tipos_imovel.filter(model => model.tipo === element)
                if(sub.length > 0 && sub[0].subtipos.length > 0){
                    sub[0].subtipos.forEach(element => {
                        this.items.sub_tipo_imovel.push(element)
                    });
                    
                }
                
            });
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            item.fac_id = this.fac_id
            item.tipo_imovel = this.params.tipo_imovel
            item.sub_tipo_imovel = this.params.sub_tipo_imovel
            const action = item.id ? 'updatePropertyProfile' : 'storePropertyProfile' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.fac_id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>