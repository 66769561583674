<template>
    <div>
        <div v-if="me.profile != 'Master' && me.profile != 'Gerente'">
            <alert-component :alert="alerts.access"></alert-component>
        </div>
        <div v-else>    
            <v-container>
                <v-row dense class="ma-0 pa-0" v-for="(portal, index) in portal_items" :key="portal.nome">
                    <v-col class="pa-3" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-btn
                            tile
                            color="primary"
                            @click="loadPortalRules(portal.id, portal.nome, portal.modelos)"
                            :class="$css_button"
                        >
                            <v-icon left>
                                mdi-pencil
                            </v-icon>
                            Editar Regra
                        </v-btn>
                    </v-col>  
                    <v-col class="pa-0 ma-0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-checkbox
                            v-model="portals[index]"
                            :value="portal.id"
                        >
                            <template v-slot:label>
                                <span :class="$css_report_list">{{ portal.nome }}</span>
                            </template>
                        </v-checkbox>
                    </v-col>
                    
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-select
                            v-model="models[index]"
                            v-if="portal.modelos && portal.modelos.length > 0"
                            :items="portal.modelos"
                            outlined
                            dense
                        >
                            <template v-slot:label>
                                <span >Modelo Anuncio</span>
                            </template>
                        </v-select>

                    </v-col>
                </v-row>    
                <v-row dense>
                    <v-col class="pa-0 ma-0 text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-btn
                            dark
                            color="primary"
                            @click="store"
                            :class="$css_button"
                        >
                        Salvar
                        </v-btn>
                    </v-col>    
                </v-row>
            </v-container>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-model="dialog_rule" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="red"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span>Regra Portal - {{ portal_rule.nome }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseRuleDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <portal-rule-component :object="portal_rule" @callLoadItem="loadItem"></portal-rule-component> 
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->
        
    </div>
</template>

<script>
import PortalRuleComponent from './partials/PortalRuleComponent.vue'
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        PortalRuleComponent,
        AlertComponent
    },

    created() {
        this.setItems()
    },
   
    computed: {
        portal_items() {
            return this.$store.state.portal_items.items.data    
        },

        me () {
          return this.$store.state.auth.me
        },
    },

    watch: {
        object: {
            handler:function() {
                this.setItems()
            },
            deep:true
        },
    },

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            alerts: {
                access: {
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['Seu perfil nāo esta autorizado a alterar as informações dos portais de propaganda. Entre em contato com seu gerente.']
                },
            },

            dialog_rule: false,
            portals:[],
            models: [],

            portal:{
                item_id: '',
                items: []
            },

            items: {
                portals: ''
            },

            portal_rule: {
                property_id: '',
                item_id: ''
            },

            portal_rule_params:{}
        }
    },
    
    methods: {

        setItems(){
            if(this.portal_items && this.portal_items.length == 0){
               this.loadPortalItems() 
            }
                
            this.portal_items.map((item, index) => {
                var result = this.object.items.filter(model => model.portal_item_id === item.id)
                if(result && result.length > 0){
                    this.portals[index] = item.id
                    this.models[index] = result[0].modelo
                }
            })

        },

        loadPortalItems(){
            this.$store.dispatch('loadPortalItems')
        },
        
        store(){
            this.portal.items = []
            this.portal.item_id = this.object.item_id
            
            this.portals.map((item, index) => {
                if(item != '' && item != undefined) {
                    if(this.models[index] == undefined){
                        this.models[index] = ''
                    }
                    this.portal.items.push({ portal_item_id: item, modelo: this.models[index] })
                }
            })

            this.$store.dispatch('storePortal', this.portal)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.object.item_id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })  
                   
        }, 

        loadPortalRules(portal_item_id, portal_nome, portal_modelos){
            this.portal_rule_params.portal_item_id = portal_item_id
            this.$store.dispatch('loadPortalRules', this.portal_rule_params)
                        .then(response => {
                            this.portal_rule = {}
                            if(response.data.length > 0){
                                this.portal_rule = response.data[0]
                            }  
                            this.portal_rule.item_id = this.object.item_id
                            this.portal_rule.portal_item_id = portal_item_id
                            this.portal_rule.nome = portal_nome
                            this.portal_rule.modelos = portal_modelos
                        })
                        .catch(error => {
                            console.log(error)
                        })

            this.dialog_rule = true                        
        },   
        
        openCloseRuleDialog(){
            this.dialog_rule = !this.dialog_rule
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },

    },
}
</script>