<template>
    <div>
        <v-row class="mt-4">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:[`item.codigo`]="{ item }">
                        <v-chip
                            label
                            small
                            color="blue lighten-5" 
                        >
                            <span style="font-size:12px">{{ item.codigo }}</span> 
                        </v-chip> 
                    </template>
                    
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            :color="item.status | statusPAI"
                            label
                            small
                            :class="$css_report_list"
                        >
                            {{ item.status }}
                        </v-chip> 
                    </template>

                    <template v-slot:[`item.nome`]="{ item }">
                        <span :class="$css_report_list">{{ item.nome }}</span> 
                    </template>

                    <template v-slot:[`item.endereco`]="{ item }">
                        <span :class="$css_report_list">{{ item.endereco_completo }}</span> 
                    </template>
                    

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="showItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar</span>
                            </v-tooltip>
                            
                            <v-tooltip top v-if="permissions.update == true">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>

                             <v-tooltip v-if="me.profile == 'Master' || me.profile == 'Gerente'" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {

    props: {
        object: {},

        filters: {},

        permissions: {},
    }, 

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'CODIGO', value: 'codigo' , sortable: false}, 
                { text: 'STATUS', value: 'status' , sortable: false},  
                { text: 'NOME', value: 'nome' , sortable: false},
                { text: 'ENDEREÇO', value: 'endereco' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    filters: {
        userProfileColor (value) {
            if(!value) return ''
            if(value == 'Gerente') return 'blue lighten-4'
            else if(value == 'Master') return 'orange lighten-4'
            else if(value == 'Corretor') return 'green lighten-4'
            else return ''
        },
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },

        showItem(id){
            this.$emit('callShowItem', id)
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyBranch', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Delete')
                            this.$emit('callLoadItems')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },
    },
}
</script>
